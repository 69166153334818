<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import { getNotification } from '@/api/Notifications.js'
  import { Notification } from "@/models/Notification";

  /**
   * Basic-table component
   */
  export default {
    page: {
      title: "Notificações",
      meta: [{ name: "description", content: appConfig.description }],
    },
    components: { Layout, PageHeader },
    data() {
      return {
        title: "Notificações",
        items: [
          {
            text: "Home",
            href: "/",
          },
          {
            text: "Notificações",
            href: "/notifications",
          },
          {
            text: "Detalhes",
            active: true,
          },
        ],
        notification: new Notification()
      };
    },
    created () {
      this.fetchData();
    },
    methods: {
      fetchData() {
        let vm = this
        getNotification(this.$route.params.id).then(response => {
            vm.notification = new Notification(response)
        })
      },
    },
  };
</script>

<template>
  <Layout>
    <PageHeader
      :title="title"
      :items="items"
    />
    
    <div class="card">
      <div class="card-body">
        <h4 class="mb-3 font-size-18">
          {{ notification.title }}
        </h4>
  
        <div v-html="notification.body"></div>
        
        <div class="text-muted text-right"><small>{{ $d(notification.created_at, 'long') }}</small></div>
      </div>
    </div>
  </Layout>
</template>
